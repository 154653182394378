import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'ItemReceiptItemTask',
    })
  },
  store (id, itemReceiptId, comment, requestDueDatetime) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'ItemReceiptItemTask',
      data: {
        item_receipt_id: itemReceiptId,
        item_receipt_item_id: id,
        body: comment,
        // notification_user: selectedUsers,
        request_due_date: requestDueDatetime,
      },
    })
  },
  delete (id) {
    return axios({
      method: 'delete',
      url: apiConfig.apiUri + 'ItemReceiptItemTask/' + id,
    })
  },
}
