<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        exact
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-alarm-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Order & Item Request</span>
      </v-card-title>
      <v-card-text>
        <h4>
          Step 1 Select item you want to set request due, select all to set request for order *
        </h4>
        <div class="title">
          Order: {{ receiptInfo.po_number }}
          &middot;
          Vendor: {{ receiptInfo.vendor }}
          &middot;
          Create By: {{ receiptInfo.user.name }}
          &middot;
          Category: {{ receiptInfo.category.name }}
          &middot;
          Status: {{ receiptInfo.task_status.name }}
          <br>
          Item Receipt Date: {{ receiptInfo.item_receipts_date }}
          &middot;
          Request Due Date: {{ receiptInfo.request_due_date }}
        </div>
        <material-card>
          <v-data-table
            v-model="selectedItems"
            :headers="itemReceiptHeader"
            :items="receiptInfo.items"
            item-key="id"
            show-select
            dense
          />
        </material-card>
        <br>
        <v-form
          ref="form"
          lazy-validation
        >
          <h4>
            Step 2 Set datetime you want items/order complete *
          </h4>
          <v-text-field
            v-model="requestDueDatetime"
            type="datetime-local"
            required
          ></v-text-field>
          <br>
          <h4>
            Step 3 Message you want testing team know
          </h4>
          <v-textarea
            name="input-7-1"
            v-model="comment"
            hint="Hint text"
          />
          <small>*indicates required field</small>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitOrder(receiptInfo)"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

  import MaterialCard from '../../../components/MaterialCard'
  import receiptTaskApi from '../../../api/receipt-task'
  import receiptItemTaskApi from '../../../api/receipt-item-task'
  import { get } from 'vuex-pathify'
  import store from '@/store'
  export default {
    name: 'OrderTask',
    components: { MaterialCard },
    props: {
      receiptInfo: {
        type: Object,
      },
    },
    computed: {
      ...get('receipt', ['filter']),
    },
    data () {
      return {
        dialog: false,
        valid: true,
        itemReceiptHeader: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'item_part_number',
          },
          { text: 'Bin #', value: 'item_bin', sortable: false },
          { text: 'Total', value: 'label_show_quantity' },
          { text: 'T/F/B', value: 'tfb' },
          { text: 'Status', value: 'task_status.name' },
          { text: 'First Item Due', value: 'item_first_due_date' },
          { text: 'Request', value: 'request_due_date' },
        ],
        selectedItems: [],
        requestDueDatetime: '',
        requestDueDatetimeRule: [
          v => !!v || 'Due data must be select',
        ],
        comment: '',
      }
    },
    methods: {
      submitOrder (receiptInfo) {
        if (this.selectedItems.length === receiptInfo.items.length) {
          this.submitReceiptRequest(receiptInfo.id)
        } else {
          this.submitReceiptItemRequest(receiptInfo.id)
        }
      },
      submitReceiptRequest (id) {
        receiptTaskApi.store(id, this.comment, this.requestDueDatetime).then(response => {
          store.set('receipt/get', this.filter)
          store.set('message/storeMessage', {
            color: response.data.type,
            text: response.data.message,
            timeout: 8000,
          })
        }).catch(error => {
          if (error.response.status === 422) {
            console.log(error.response.data.errors)
          }
        })
      },
      submitReceiptItemRequest (itemReceiptId) {
        this.selectedItems.forEach((item, index) => {
          receiptItemTaskApi.store(
            item.id, itemReceiptId, this.comment, this.requestDueDatetime,
          ).then(response => {
            store.set('receipt/get', this.filter)
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 8000,
            })
            this.dialog = false
          }).catch(error => {
            if (error.response.status === 422) {
              console.log(error.response.data.errors)
            }
          })
        })
      },
    },
  }
</script>

<style scoped>

</style>
