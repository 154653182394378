<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <!-- <manual-order ref="ManualOrder"></manual-order> -->
    <v-dialog
      v-model="receiptDeleteConfirmDialog"
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title class="headline">
          Please confirm you want delete Item Receipt {{ requestDeleteReceiptNumber }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="confirmDeleteReceiptNumber"
            label="Please Type Receipt Number Show Above"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="receiptDeleteConfirmDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="confirmDeleteReceiptNumber === requestDeleteReceiptNumber"
            color="red"
            text
            @click="confirmDeleteReceipt"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="pr-0 mr-5">
      <v-col
        cols="2"
        class="ma-2"
      >
        <v-btn
          icon
          class="ma-3"
          color="dark"
          small
          @click="displayManualOrder"
        >
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col
        cols="6"
        md="2"
      >
        <v-select
          v-model="filter.order"
          :items="dataOrderItems"
          label="Order By"
          item-text="name"
          item-value="id"
          @change="orderReceiptData"
        />
      </v-col>
      <v-col
        cols="2"
        class="mt-2"
      >
        <v-btn
          color="accent"
          class="ma-3"
          dark
          small
          @click="filterDialog = true"
        >
          <v-icon>mdi-filter-variant</v-icon> & <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
      <v-dialog
        v-model="filterDialog"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Filter & search
          </v-card-title>
          <v-card-text>
            <v-col cols="12">
              <v-text-field
                v-model="filter.key_word"
                label="Key Word"
                hint="Click clear when you want show all result"
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="filter.categoryId"
                :items="receiptCategory"
                label="Category"
                item-text="name"
                item-value="id"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="filter.taskStatusId"
                :items="taskStatus"
                label="Task Status"
                item-text="name"
                item-value="id"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="filter.date_from"
                :items="[10,20,30,50,60,90,'All']"
                label="Receipt From (Days)"
              />
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn
                  block
                  color="info"
                  @click="resetFilter"
                >
                  Reset Filter
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="accent"
                  @click="itemReceiptIndexData"
                >
                  Let's GO
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row
      class="mx-5"
      align="center"
    >
      <v-col
        style="text-align: center; font-size: small"
      >
        {{ receiptStatusUpdateMessage }}
        <v-btn
          v-if="!receiptStatusUpdateReady"
          color="blue"
          text
          small
          @click="showInTestingDone()"
        >
          Show Result
        </v-btn>
        |
        <v-btn
          v-if="!receiptStatusUpdateReady"
          color="blue"
          text
          small
          @click="syncReceiptStatus('sync_request')"
        >
          Sync Data
        </v-btn>
      </v-col>
    </v-row>
    <real-time-testing-update-board></real-time-testing-update-board>
    <material-card
      v-for="itemReceipt in receipt"
      :key="itemReceipt.id"
      icon="mdi-clipboard-text"
      icon-small
      :title="itemReceipt.po_number"
      color="accent"
      class="mx-5"
    >
      <v-card-text>
        <v-row>
          Current Location: {{ itemReceipt.location ? itemReceipt.location.name : 'Unknow' }},
          PO Status: {{ itemReceipt.po_closed === true ? 'Closed' : 'Open' }}
          <v-spacer></v-spacer>
          <v-chip
            v-if="itemReceipt.location && itemReceipt.location.name !== 'Testing'"
            color="blue"
            text-color="white"
            small
          >
            SOS Receipt Closed
          </v-chip>
          <v-chip
            v-if="itemReceipt.po_closed === true"
            color="green"
            text-color="white"
            small
            class="mx-2"
          >
            SOS PO Closed
          </v-chip>
        </v-row>
        <v-row>
          Vendor: {{ itemReceipt.vendor }}
          RMA Rate:
          Failed Rate:
          Create By: {{ itemReceipt.user.name }}
        </v-row>
        <v-row>
          Item Receipt Date: {{ itemReceipt.item_receipt_date }}
          |
          Item First Due Date: {{ itemReceipt.item_first_due_date }}
          <v-spacer />
          <v-btn
            text
            small
            @click="receiptShow(itemReceipt.id)"
          >
            Show Receipt
          </v-btn>
          <v-btn
            v-if="itemReceipt.is_sos_order === 0"
            text
            small
            @click="editManualReceipt(itemReceipt.id)"
          >
            Edit Receipt
          </v-btn>
          <v-divider vertical />
          <v-btn
            text
            color="red"
            small
            @click="deleteReceipt(itemReceipt.id, itemReceipt.po_number)"
          >
            Delete Receipt
          </v-btn>
        </v-row>
      </v-card-text>
      <v-data-table
        dense
        :headers="itemReceiptItemHeader"
        :items="itemReceipt.items"
        sort-by="calories"
        class="elevation-1"
        :item-class="markSpecialItem"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>
              <v-icon
                v-if="itemReceipt.is_request"
                color="red"
              >
                mdi-flag
              </v-icon>
              {{ itemReceipt.category.name }}
            </v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            />
            <v-toolbar-title>{{ itemReceipt.task_status.name }}</v-toolbar-title>
            <v-spacer />
            <order-task :receipt-info="itemReceipt" />
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.remain_days="{ item }">
          <v-icon
            v-if="item.is_request"
            color="red"
          >
            mdi-flag
          </v-icon>
          {{ item.remain_days }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="reportItemTest(item)"
          >
            mdi-file-document-multiple
          </v-icon>
        </template>
      </v-data-table>
    </material-card>
    <div class="text-center">
      <v-pagination
        v-model="meta.current_page"
        :length="meta.last_page"
        circle
        :total-visible="7"
        @input="changePage"
      />
    </div>
    <div class="py-3" />
    <snack-bar ref="snackBar" />
  </v-container>
</template>

<script>
  import RealTimeTestingUpdateBoard from '@/components/receipt/RealTimeTestingUpdateBoard'
  import itemReceiptApi from '../api/item-receipt'
  // import { itemReceiptIndex } from '../util/item-receipt-data-handle'
  import OrderTask from '@/layouts/default/widgets/OrderTask'
  import MaterialCard from '@/components/MaterialCard'
  import { get, sync } from 'vuex-pathify'
  import store from '@/store'
  // import moment from 'moment'
  // import ManualOrder from '../layouts/default/widgets/ManualOrder'
  import Echo from 'laravel-echo'
  export default {
    name: 'ItemReceipt',
    components: { MaterialCard, OrderTask /* , ManualOrder */, RealTimeTestingUpdateBoard },
    data () {
      return {
        // manualOrderDialog: false,
        receiptDeleteConfirmDialog: false,
        requestDeleteReceiptNumber: '',
        confirmDeleteReceiptNumber: '',
        editItemReceipt: 0,
        filterDialog: false,
        defaultFilter: {
          categoryId: 0,
          taskStatusId: 0,
          key_word: '',
          order: 'recent_updated',
          date_from: 60,
        },
        dataOrderItems: [
          { id: 'old_created', name: 'Created Oldest' },
          { id: 'recent_created', name: 'Created Recent' },
          { id: 'old_updated', name: 'Updated Oldest' },
          { id: 'recent_updated', name: 'Updated Recent' },
        ],
        dialog: false,
        dialogDelete: false,
        itemReceiptItemHeader: [
          {
            text: 'Item',
            align: 'start',
            sortable: false,
            value: 'item_part_number',
          },
          { text: 'Bin #', value: 'item.bin_number' },
          { text: 'Total', value: 'label_show_quantity' },
          { text: 'Lines', value: 'sos_line_info' },
          { text: 'Tested/Failed/Broken', value: 'tfb' },
          { text: 'Status', value: 'task_status.name' },
          { text: 'Remains (Days)', value: 'remain_days' },
          { text: 'Updated', value: 'updated_at' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        itemReceiptData: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          calories: 0,
          fat: 0,
          carbs: 0,
          protein: 0,
        },
        defaultItem: {
          name: '',
          calories: 0,
          fat: 0,
          carbs: 0,
          protein: 0,
        },
        receiptStatusUpdateReady: false,
        receiptStatusUpdateMessage: 'There is no receipt status change information right now.',
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      ...get('receipt', [
        'receipt',
        'meta',
        'receiptLoad',
      ]),
      ...get('task', [
        'taskStatus',
        'taskStatusLoad',
      ]),
      ...get('category', [
        'receiptCategory',
        'receiptCategoryLoad',
      ]),
      ...sync('receipt', [
        'filter',
      ]),
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      receiptDeleteConfirmDialog (newVal, oldVal) {
        if (oldVal === true && newVal === false) {
          this.requestDeleteReceiptNumber = ''
          this.confirmDeleteReceiptNumber = ''
        }
      },
    },
    created () {
      this.itemReceiptIndexData()
      this.syncReceiptStatus('info_request')
      store.set('task/get')
      store.set('category/getReceiptCategory')
    },
    mounted () {
      window.Echo.channel('receipt-status-updated')
        .listen('.itemReceiptStatusUpdateLocationCheck', (response) => {
          this.receiptStatusUpdateReady = response.reqiest_update
          this.receiptStatusUpdateMessage = response.message
        })
    },
    methods: {
      itemReceiptIndexData () {
        if (this.filterDialog === true) this.filterDialog = false
        store.set('receipt/get', this.filter).then(response => {
          // console.log(response)
        }).catch(error => {
          console.log(error)
        })
      },
      orderReceiptData () {
        this.itemReceiptIndexData()
      },
      showInTestingDone () {
        const filter = {
          categoryId: 0,
          taskStatusId: 0,
          key_word: 'testing-done-in-testing',
          order: 'recent_updated',
          date_from: 0,
        }
        store.set('receipt/get', filter).then(response => {
          // console.log(response)
        }).catch(error => {
          console.log(error)
        })
      },
      syncReceiptStatus (type) {
        itemReceiptApi.syncReceiptStatus(type).then(response => {
          if (type === 'info_request') {
            this.receiptStatusUpdateReady = response.data.data.reqiest_update
            this.receiptStatusUpdateMessage = response.data.data.message
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error.response.data.message,
            timeout: 3500,
          })
        })
        if (type === 'sync_request') {
          store.set('message/storeMessage', {
            color: 'success',
            text: 'Your request successful send to SOS, we will let you know result soon. The process may take around 5 mins, It depends on the response speed for SOS, and the number of orders',
            timeout: 8000,
          })
        }
      },
      resetFilter () {
        store.set('receipt/resetFilter', this.defaultFilter)
      },
      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      receiptShow (id) {
        this.$router.push({ name: 'Receipt Show', params: { id: id } }).catch(() => {})
      },
      editManualReceipt (id) {
        this.$router.push({ name: 'Receipt/CreateEditManual', params: { id: id } }).catch(() => {})
      },
      deleteReceipt (id, poNumber) {
        this.receiptDeleteConfirmDialog = true
        this.requestDeleteReceiptNumber = poNumber
        this.editItemReceipt = id
      },
      confirmDeleteReceipt () {
        if (this.requestDeleteReceiptNumber === this.confirmDeleteReceiptNumber) {
          itemReceiptApi.deleteItemReceipt(this.editItemReceipt).then(response => {
            if (response.data.code === 200) {
              this.itemReceiptData = this.itemReceiptData.filter(element => element.id !== this.editItemReceipt)
              this.receiptDeleteConfirmDialog = false
              store.set('receipt/get', this.filter)
            } else {
              store.set('message/storeMessage', {
                color: response.data.type,
                text: response.data.message,
                timeout: 3000,
              })
              this.$refs.snackBar.activeStatusInfo()
            }
          }).catch(error => {
            store.set('message/storeMessage', {
              color: 'error',
              text: error,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          })
        }
      },
      reportItemTest (item) {
        this.$router.push({ name: 'Receipt Item', params: { id: item.id } }).catch(() => {})
      },
      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
      markSpecialItem (item) {
        if (item.removed_at) return 'table-remove-item table-done-item'
        if (item.task_status_id === 4) return 'table-issue-item table-done-item'
        if (item.task_status_id >= 3) return 'table-done-item'
      },
      changePage (page) {
        console.log(page)
        const params = { page: page, filter: this.filter }
        store.set('receipt/changePage', params).then(this.$vuetify.goTo(0))
      },
      displayManualOrder () {
        // this.$refs.ManualOrder.dialogOn()
        this.$router.push({ name: 'Receipt/CreateEditManual', params: { id: '-1' } }).catch(() => {})
      },
    },
  }
</script>

<style scoped>

</style>
